import { Main } from "@actions/index";
import { Reducer } from "redux";
import { NetworkingStats } from "@interface/entities/networkingStats";
import { BaseState, BaseAction } from "@interface/common";

const InitialState: BaseState<NetworkingStats> = {
  data: {
    networking_users: 0,
    new_networking_users: 0,
    networking_companies: 0,
    new_networking_companies: 0,
    meeting_scheduled: 0,
    new_meeting_scheduled: 0,
    cutaway_sent: 0,
    new_cutaway_sent: 0,
  },
};

const reducer: Reducer<BaseState<NetworkingStats>, BaseAction<NetworkingStats>> = (
  state = InitialState,
  action
) => {
  switch (action.type) {
    case Main.NetworkingStats.SET_DATA:
      return { ...state, data: { ...state.data, ...action.data } };
    default:
      return state;
  }
};

export default reducer;
